import React, { useState, useEffect } from "react";
import { BrowserRouter, Routes, Route, json } from "react-router-dom";
import "./style/dark.scss";
import { useContext } from "react";
import { DarkModeContext } from "./context/darkModeContext";
import Allemployers from "./components/allemployer/Allemployers";
import Approvejob from "./components/approvejob/Approvejob";
import Particularjob from "./components/particularjob/Particularjob";
import Home from "./pages/home/Home";
import LoginForm from "./pages/login/Login";
import PrivateRoute from "./privateroute";
import Logout from "./components/logout/Logout";
import Profile from "./components/profile/Profile";
import AllJobs from "./pages/jobsection/AllJobs";
import ApprovedJobs from "./pages/jobsection/ApprovedJobs";
import RejectedJobs from "./pages/jobsection/RejectedJobs";

import CreateConfigRoles from "./pages/manageroles/CreateSubadmin";
import ManageRoles from "./pages/manageroles/ManageRoles";
import ConfigRoles from "./pages/manageroles/ConfigRoles";

import Candidates from "./pages/createcandidates/Candidates";
import CreateCandidates from "./pages/createcandidates/CreateCandidates";
import EditCandidate from "./pages/createcandidates/EditCandidate";
import CandidateDetails from "./pages/createcandidates/CandidateDetails";

import Employer from "./pages/createemployers/Employer";
import CreateEmployer from "./pages/createemployers/CreateEmployer";
import EmployerDetails from "./pages/createemployers/EmployerDetails";

import JobShayak from "./pages/createjobsahayak/JobSahayak";
import CreateJobsahayak from "./pages/createjobsahayak/CreateJobsahayak";

import Partner from "./pages/createpartner/Partner";
import CreatePartner from "./pages/createpartner/CreatePartner";
import ViewCandidate from "./pages/createpartner/ViewCandidate";
import PartnerDetails from "./pages/createpartner/PartnerDetails";

import CreateNewUsers from "./pages/createnewusers/CreateNewUsers";
import GenerateQR from "./pages/generateqr/GenerateQR";
import ManageQR from "./pages/manageqr/ManageQR";
import JobCategory from "./pages/jobcategories/JobCategory";
import GlobalStateProvider from "./components/GlobalStateProvide/GlobalStateProvider";
import GlobalSnackBar from "./components/GlobalSnackBar/GlobalSnackBar";
import ForgotPassword from "./pages/forgotpasswordpage/ForgotPassword";
import ChangePasswordPage from "./pages/changepasswordpage/ChangePasswordPage";
import JobConfiguration from "./pages/jobconfiguration/JobConfiguration";
import Unauthorized from "./components/unauthorized/Unauthorized";

import Announcement from "./pages/jobseva/Announcement";
import CreateAnnouncement from "./pages/jobseva/CreateAnnouncement";
import JobShayakDetails from "./pages/createjobsahayak/JobSahayakDetails";
import JobDescription from "./pages/jobsection/JobDescription";
import JobsevaJobDescription from "./pages/jobsection/JobsevaJobDescription";
import JobSevaAllJobs from "./pages/jobseva/JobSevaAllJobs";
import JwpTask from "./pages/jobseva/JwpTask";
import JwpDeployTask from "./pages/jobseva/JwpDeployTask";
import JSInfo from "./pages/jobseva/JSInfo";
// import CreateAnnouncement from './pages/jobseva/CreateAnnouncement';
import CreateNewTask from "./pages/jobseva/CreateNewTask";
import CandidateKyc from "./pages/createcandidates/CandidateKyc";
import JwpList from "./pages/jobseva/JwpList";
import Jsk from "./pages/jobseva/Jsk";
import CreateJWP from "./pages/jobseva/CreateJWP";
import CandidateView from "./pages/jobseva/CandidateView";
import CandidateUpload from "./pages/createpartner/CandidateUpload";
import WhatsAppCampaign from "./pages/marketing/WhatsAppCampaign";
import CreateWhatsapp from "./pages/marketing/CreateWhatsapp";
import EmailCampaign from "./pages/marketing/EmailCampaign";
import CreateEmail from "./pages/marketing/CreateEmail";
import SmsCampaign from "./pages/marketing/SmsCampaign";
import CreateSms from "./pages/marketing/CreateSms";
import CreateJSK from "./pages/jobseva/CreateJSK";
import AssignJobSahayak from "./pages/jobseva/AssignJobSahayak";
import JobSectors from "./pages/jobSectors/JobSectors";
import CreateJobSectors from "./pages/jobSectors/CreateJobSectors";
import CreateOccupations from "./pages/jobOccupations/CreateOccupations";
import Occupations from "./pages/jobOccupations/Occupations";
import JobRoles from "./pages/jobRoles/jobRoles";
import CreateJobRoles from "./pages/jobRoles/CreateJobRoles";
import JobSecOccuRole from "./pages/JobSectOccuRole/JobSecOccuRole";
import Recruiter from "./pages/createRecruiter/Recruiter";
import CreateRecruiter from "./pages/createRecruiter/CreateRecruiter";
import ViewClient from "./pages/createRecruiter/ViewClient";
import AddClient from "./pages/createRecruiter/AddClient";
import ViewClientDetails from "./pages/createRecruiter/ViewClientDetail";
import ViewJob from "./pages/jobsection/ViewJob";
import Notifications from "./pages/marketing/Notification";
import CreateNotification from "./pages/marketing/CreateNotification";
import Testing from "./pages/createcandidates/testing";
import WalletAmount from "./pages/createemployers/WalletAmount";
import UserMessages from "./pages/userMessages/UserMessages";
import UserQueries from "./pages/userQueries/UserQueries";
import Transactions from "./pages/payments/Transactions";
import ResumeView from "./pages/resumeView";

function App() {
  const { darkMode } = useContext(DarkModeContext);
  // const [resourcesNamesList, setResourcesNamesList] = useState(null);

  // let resourcesNamesList = JSON.parse(localStorage.getItem('resourceNames'));
  // console.log("resourcesNamesList :" + resourcesNamesList);

  const triggerFunctionInApp = () => {
    console.log("Function in App.js triggered from Login.js");
    window.location.reload();
  };

  window.myFunctionToTrigger = triggerFunctionInApp;

  return (
    <GlobalStateProvider>
      <div className={darkMode ? "app dark" : "app"}>
        <BrowserRouter>
          <GlobalSnackBar />
          <Routes>
            <Route path="/" element={<LoginForm />} />
            <Route path="/unauthorized" element={<Unauthorized />} />
            <Route path="/forgotpassword" element={<ForgotPassword />} />
            <Route path="/changepassword" element={<ChangePasswordPage />} />
            <Route
              path="/dashboard"
              element={<PrivateRoute element={Home} />}
            />
            <Route path="/resume-view" element={<ResumeView />} />
            <Route path="/resume-check-admin" element={<ResumeView />} />
            <Route
              path="/employers"
              element={<PrivateRoute element={Allemployers} />}
            />
            <Route
              path="/update/wallet-amount/:id"
              element={<PrivateRoute element={WalletAmount} />}
            />
            <Route
              path="/jobconfiguration"
              element={<PrivateRoute element={JobConfiguration} />}
            />

            {/* <Route path="/jobapprove" element={<PrivateRoute element={Approvejob} />} />
            <Route path="/jobapprove/:id" element={<PrivateRoute element={Particularjob} />} /> */}
            {/* <Route path="/profile" element={<PrivateRoute element={Profile} />} /> */}
            <Route
              path="/all-jobs"
              element={<PrivateRoute element={AllJobs} />}
            />
            <Route
              path="/all-jobs/view-job"
              element={<PrivateRoute element={ViewJob} />}
            />
            <Route
              path="/job-description"
              element={<PrivateRoute element={JobDescription} />}
            />
            {/* <Route path="/approved-jobs" element={<PrivateRoute element={ApprovedJobs} />} /> */}
            <Route
              path="/rejected-jobs"
              element={<PrivateRoute element={RejectedJobs} />}
            />

            <Route
              path="/manage-roles"
              element={<PrivateRoute element={ManageRoles} />}
            />
            <Route
              path="/manage-roles/create"
              element={<PrivateRoute element={CreateConfigRoles} />}
            />

            <Route
              path="/config-roles"
              element={<PrivateRoute element={ConfigRoles} />}
            />
            <Route
              path="/testing"
              element={<PrivateRoute element={Testing} />}
            />

            <Route
              path="/candidate"
              element={<PrivateRoute element={Candidates} />}
            />

            <Route
              path="/candidate/create"
              element={<PrivateRoute element={CreateCandidates} />}
            />
            <Route
              path="/candidate/edit/:id?"
              element={<PrivateRoute element={EditCandidate} />}
            />
            <Route
              path="/candidate/detail/:candidateId"
              element={<PrivateRoute element={CandidateDetails} />}
            />
            <Route
              path="/candidate/assign-js"
              element={<PrivateRoute element={CandidateKyc} />}
            />

            <Route
              path="/employer"
              element={<PrivateRoute element={Employer} />}
            />
            <Route
              path="/employer/create"
              element={<PrivateRoute element={CreateEmployer} />}
            />
            <Route
              path="/employer/edit/:id?"
              element={<PrivateRoute element={EditCandidate} />}
            />
            <Route
              path="/employer/detail/:employerId"
              element={<PrivateRoute element={EmployerDetails} />}
            />

            <Route
              path="/recruiter"
              element={<PrivateRoute element={Recruiter} />}
            />
            <Route
              path="/recruiter/create"
              element={<PrivateRoute element={CreateRecruiter} />}
            />
            <Route
              path="/recruiter/edit/:id?"
              element={<PrivateRoute element={EditCandidate} />}
            />
            {/* <Route path="/recruiter/detail/:recruiterId" element={<PrivateRoute element={EmployerDetails} />} /> */}
            <Route
              path="/recruiter/client"
              element={<PrivateRoute element={ViewClient} />}
            />
            <Route
              path="/recruiter/client/create"
              element={<PrivateRoute element={AddClient} />}
            />
            <Route
              path="/recruiter/detail/:candidateId"
              element={<PrivateRoute element={ViewClientDetails} />}
            />

            <Route
              path="/jobsahayak"
              element={<PrivateRoute element={JobShayak} />}
            />
            <Route
              path="/jobsahayak/create"
              element={<PrivateRoute element={CreateJobsahayak} />}
            />
            <Route
              path="/jobsahayak/edit/:id?"
              element={<PrivateRoute element={CreateJobsahayak} />}
            />
            <Route
              path="/jobsahayak/detail/:jobSahayakId"
              element={<PrivateRoute element={JobShayakDetails} />}
            />

            <Route
              path="/partner"
              element={<PrivateRoute element={Partner} />}
            />
            <Route
              path="/partner/create"
              element={<PrivateRoute element={CreatePartner} />}
            />
            <Route
              path="/partner/edit/:id?"
              element={<PrivateRoute element={CreatePartner} />}
            />
            <Route
              path="/partner/:id?/view-candidates"
              element={<PrivateRoute element={ViewCandidate} />}
            />
            <Route
              path="/partner/detail/:partnerId"
              element={<PrivateRoute element={PartnerDetails} />}
            />
            <Route
              path="/partner/candidate-upload"
              element={<PrivateRoute element={CandidateUpload} />}
            />

            <Route
              path="/create-new-users/:id?"
              element={<PrivateRoute element={CreateNewUsers} />}
            />
            <Route
              path="/generate-qr"
              element={<PrivateRoute element={GenerateQR} />}
            />
            <Route
              path="/manage-qr"
              element={<PrivateRoute element={ManageQR} />}
            />
            {/* <Route path="/job-categories" element={<PrivateRoute element={JobCategory} />} /> */}
            <Route path="/logout" element={<Logout />} />

            <Route
              path="/announcement"
              element={<PrivateRoute element={Announcement} />}
            />
            <Route
              path="/announcement/create"
              element={<PrivateRoute element={CreateAnnouncement} />}
            />
            <Route
              path="/create-new-task/:id?"
              element={<PrivateRoute element={CreateNewTask} />}
            />
            <Route
              path="/jobseva-all-jobs"
              element={<PrivateRoute element={JobSevaAllJobs} />}
            />
            <Route
              path="/jobseva/job-description"
              element={<PrivateRoute element={JobsevaJobDescription} />}
            />
            <Route
              path="/jwp-task"
              element={<PrivateRoute element={JwpTask} />}
            />
            <Route
              path="/jwp-task/deploy"
              element={<PrivateRoute element={JwpDeployTask} />}
            />
            <Route
              path="/jwp-task/deploy/:page_name"
              element={<PrivateRoute element={JSInfo} />}
            />
            <Route
              path="/jwp-task/candidates/:taskId?"
              element={<PrivateRoute element={CandidateView} />}
            />
            <Route path="/jwp" element={<PrivateRoute element={JwpList} />} />
            <Route path="/jsk" element={<PrivateRoute element={Jsk} />} />
            <Route
              path="/jwp/create"
              element={<PrivateRoute element={CreateJWP} />}
            />
            <Route
              path="/jsk/create"
              element={<PrivateRoute element={CreateJSK} />}
            />
            <Route
              path="/:path/assign-josahayak"
              element={<PrivateRoute element={AssignJobSahayak} />}
            />

            <Route
              path="/whatsapp-campaign"
              element={<PrivateRoute element={WhatsAppCampaign} />}
            />
            <Route
              path="/whatsapp-campaign/create"
              element={<PrivateRoute element={CreateWhatsapp} />}
            />
            <Route
              path="/email-campaign"
              element={<PrivateRoute element={EmailCampaign} />}
            />
            <Route
              path="/email-campaign/create"
              element={<PrivateRoute element={CreateEmail} />}
            />
            <Route
              path="/sms-campaign"
              element={<PrivateRoute element={SmsCampaign} />}
            />
            <Route
              path="/sms-campaign/create"
              element={<PrivateRoute element={CreateSms} />}
            />

            <Route
              path="/job-sectors"
              element={<PrivateRoute element={JobSectors} />}
            />
            <Route
              path="/job-sectors/create"
              element={<PrivateRoute element={CreateJobSectors} />}
            />
            <Route
              path="/job-occupations"
              element={<PrivateRoute element={Occupations} />}
            />
            <Route
              path="/job-occupations/create"
              element={<PrivateRoute element={CreateOccupations} />}
            />
            <Route
              path="/job-roles"
              element={<PrivateRoute element={JobRoles} />}
            />
            <Route
              path="/job-roles/create"
              element={<PrivateRoute element={CreateJobRoles} />}
            />
            <Route
              path="/job-categories"
              element={<PrivateRoute element={JobSecOccuRole} />}
            />
            <Route
              path="/notifications"
              element={<PrivateRoute element={Notifications} />}
            />
            <Route
              path="/notifications/create"
              element={<PrivateRoute element={CreateNotification} />}
            />
            <Route
              path="/seo"
              element={
                <PrivateRoute
                  element={(props) => (
                    <JobSecOccuRole {...props} pageName="seo" />
                  )}
                />
              }
            />
            <Route
              path="/chats"
              element={<PrivateRoute element={UserMessages} />}
            />
            {/* contact Us Queries */}
            <Route
              path="/contact-us-queries"
              element={<PrivateRoute element={UserQueries} />}
            />
            {/* Transactions*/}
            <Route
              path="/transactions"
              element={<PrivateRoute element={Transactions} />}
            />
            {/* <Route path="/employer/edit/:id?" element={<PrivateRoute element={EditCandidate} />} />
            <Route path="/employer/detail/:employerId" element={<PrivateRoute element={EmployerDetails} />} /> */}
          </Routes>
        </BrowserRouter>
      </div>
    </GlobalStateProvider>
  );
}

export default App;
