import axios from "axios";
import { getURL } from "./functions";
import { axiosInstance } from "../axiosconfing";
let header = {
  "Access-Control-Allow-Origin": "*",
  "Content-Type": "application/json; charset=UTF-8",
};

export const postRequestWithoutToken = async (api_url, dataToSend) => {
  try {
    const data = await axios.post(
      `${process.env.REACT_APP_API_BASE_URL}${api_url}`,
      {
        ...dataToSend,
      },
      {
        headers: header,
      }
    );
    return data;
  } catch (error) {
    throw error;
  }

  // try {
  //     let response = await fetch(getURL() + api_url, {
  //         method: "POST",
  //         headers: header,
  //         body: JSON.stringify(dataToSend)
  //     });
  //     if (response.ok) {
  //         response = await response.json();
  //         return response;
  //     }
  //     else {
  //         throw new Error("Request Failed")
  //     }
  // } catch (error) {
  //     console.error("Error", error);
  //     throw error;
  // }
};

/* Get Request */
export const getRequest = async (api_url) => {
  try {
    const data = await axios.get(
      `${process.env.REACT_APP_API_BASE_URL}${api_url}`,
      { headers: header }
    );
    return data;
  } catch (error) {
    throw error;
  }

  // try {
  //     let response = await fetch(getURL() + api_url, {
  //         method: "GET",
  //         headers: header,
  //     });
  //     if (response.ok) {
  //         response = await response.json();
  //         return response;
  //     }
  //     else {
  //         throw new Error("Request Failed")
  //     }
  // } catch (error) {
  //     console.error("Error", error);
  //     throw error;
  // }
};
export const getRequestQa = async (api_url) => {
  try {
    const data = await axios.get(
      `${process.env.REACT_APP_API_BASE_URL_V3}${api_url}`,
      { headers: header }
    );
    return data;
  } catch (error) {
    throw error;
  }

  // try {
  //     let response = await fetch(getURL() + api_url, {
  //         method: "GET",
  //         headers: header,
  //     });
  //     if (response.ok) {
  //         response = await response.json();
  //         return response;
  //     }
  //     else {
  //         throw new Error("Request Failed")
  //     }
  // } catch (error) {
  //     console.error("Error", error);
  //     throw error;
  // }
};
export const getRequestChatApi = async (api_url) => {
  try {
    const token = process.env.REACT_APP_CHAT_TOKEN; // access token from environment variable
    const data = await axios.get(
      `${process.env.React_APP_CHAT_API_URL}${api_url}`,
      {
        headers: {
          ...header,
          Authorization: `Bearer ${token}` // add the token here
        }
      }
    );
    return data.data;
  } catch (error) {
    throw error;
  }
};

export const postRequest = async (api_url, bodyData, headers) => {
  // let fetch_url = getURL() + api_url;

  // console.log(fetch_url, " this is fethc url");
  // let token = localStorage.getItem("token");
  // if (token != null)
  //     header = {
  //         'Content-Type': 'application/json; charset=UTF-8',
  //         'authorization': 'Bearer ' + token
  //     };

  try {
    const data = await axiosInstance.post(api_url, bodyData);
    return data;
  } catch (error) {
    throw error;
  }
  // let data = await fetch(fetch_url, {
  //     method: "POST",
  //     headers: header,
  //     body: JSON.stringify(bodyData),
  // });
  // data = data.json();

  // return data;
};

export const postRequestBeta = async (api_url, bodyData, additionalHeaders) => {
  let fetch_url = getURL() + api_url;
  console.log(fetch_url, " this is fetch url");

  let token = localStorage.getItem("token");
  console.log("token....................", token);

  let headers = {
    "Content-Type": "application/json; charset=UTF-8",
  };

  if (token !== null) {
    headers["Authorization"] = "Bearer " + token;
  }

  // Include any additional headers from the 'additionalHeaders' parameter
  if (additionalHeaders) {
    Object.assign(headers, additionalHeaders);
  }

  try {
    const data = await axios.post(
      `${process.env.REACT_APP_API_BASE_URL_BETA}${api_url}`,
      bodyData,
      {
        headers: headers,
      }
    );
    return data;
  } catch (error) {
    throw error;
  }
};

export const getRequestWithToken = async (api_url) => {
  try {
    const data = await axiosInstance.get(api_url);
    return data;
  } catch (error) {
    throw error;
  }
};

export const patchRequestWithToken = async (api_url, bodyData) => {
  // let fetch_url = getURL() + api_url;
  // let token = localStorage.getItem("token");

  try {
    const data = await axiosInstance.patch(api_url, bodyData);
    return data;
  } catch (error) {
    throw error;
  }

  // if (token != null)
  //     header = {
  //         'Access-Control-Allow-Origin': "*",
  //         'authorization': 'Bearer ' + token
  //     };

  // let data = await fetch(fetch_url, {
  //     method: "PATCH",
  //     headers: header,
  //     body: JSON.stringify(bodyData),
  // });
  // if (data.ok) {
  //     data = await data.json();
  //     return data;
  // }
};

export const deleteRequest = async (api_url, optionData) => {
  // let fetch_url = getURL() + api_url;
  // let token = localStorage.getItem("token");

  try {
    const data = await axiosInstance.delete(api_url, {
      data: optionData,
    });
    return data;
  } catch (error) {
    throw error;
  }

  // if (token != null)
  //     header = {
  //         'Access-Control-Allow-Origin': "*",
  //         'authorization': 'Bearer ' + token
  //     };

  // let data = await fetch(fetch_url, {
  //     method: "DELETE",
  //     headers: header,
  //     body: JSON.stringify(bodyData),
  // });
  // if (data.ok) {
  //     data = await data.json();
  //     return data;
  // }
};

export const uploadImage = async (api_url, dataToSend) => {
  let storedToken = localStorage.getItem("token");
  try {
    let response = await fetch(
      `${process.env.REACT_APP_API_BASE_URL}${api_url}`,
      {
        method: "POST",
        headers: {
          Authorization: `Bearer ${storedToken}`,
        },
        body: dataToSend,
      }
    );
    if (response.ok) {
      response = await response.json();
      return response;
    } else {
      throw new Error("Request Failed");
    }
  } catch (error) {
    console.error("Error", error);
    throw error;
  }
};

export const getRequestWithTokenV3 = async (api_url) => {
  let storedToken = localStorage.getItem("token");
  let headers = {
    "Content-Type": "application/json; charset=UTF-8",
  };

  if (storedToken !== null) {
    headers[`Authorization`] = `Bearer ${storedToken}`;
  }
  try {
    let response = await fetch(
      `${process.env.REACT_APP_API_BASE_URL_V3}${api_url}`,
      {
        method: "GET",
        headers: headers,
      }
    );
    if (response.ok) {
      response = await response.json();
      return response;
    } else {
      throw new Error("Request Failed");
    }
  } catch (error) {
    throw error;
  }
};

export const postRequestWithTokenWithQA = async (api_url, dataToSend) => {
  let token = localStorage.getItem("token");

  let headers = {
    "Content-Type": "application/json; charset=UTF-8",
  };

  if (token !== null) {
    headers["Authorization"] = "Bearer " + token;
  }

  try {
    const data = await axios.post(
      `${process.env.REACT_APP_API_BASE_QA_URL}${api_url}`,
      {
        ...dataToSend,
      },
      {
        headers: header,
      }
    );
    return data;
  } catch (error) {
    throw error;
  }

  // try {
  //     let response = await fetch(getURL() + api_url, {
  //         method: "POST",
  //         headers: header,
  //         body: JSON.stringify(dataToSend)
  //     });
  //     if (response.ok) {
  //         response = await response.json();
  //         return response;
  //     }
  //     else {
  //         throw new Error("Request Failed")
  //     }
  // } catch (error) {
  //     console.error("Error", error);
  //     throw error;
  // }
};

export const getRequestTemplate = async (api_url) => {
  let storedToken = process.env.REACT_APP_API_BASE_URL_TEMPLATE_TOKEN;
  let headers = {
    "Content-Type": "application/json; charset=UTF-8",
  };

  if (storedToken !== null) {
    headers[`Authorization`] = `Bearer ${storedToken}`;
  }
  try {
    let response = await fetch(
      `${process.env.REACT_APP_API_BASE_URL_TEMPLATE}${api_url}`,
      {
        method: "GET",
        headers: headers,
      }
    );
    if (response.ok) {
      response = await response.json();
      return response;
    } else {
      throw new Error("Request Failed");
    }
  } catch (error) {
    throw error;
  }
};
